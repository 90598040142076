import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2cb3c695 = () => interopDefault(import('../pages/pac/index.vue' /* webpackChunkName: "pages/pac/index" */))
const _3af49547 = () => interopDefault(import('../pages/pac/manual-subscription.vue' /* webpackChunkName: "pages/pac/manual-subscription" */))
const _a33f0b88 = () => interopDefault(import('../pages/pac/operations-to-subscribe.vue' /* webpackChunkName: "pages/pac/operations-to-subscribe" */))
const _72247e86 = () => interopDefault(import('../pages/pac/success.vue' /* webpackChunkName: "pages/pac/success" */))
const _3e7dc372 = () => interopDefault(import('../pages/payment/down-payments/index.vue' /* webpackChunkName: "pages/payment/down-payments/index" */))
const _86c99d82 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _15d09276 = () => interopDefault(import('../pages/payment/installments/index.vue' /* webpackChunkName: "pages/payment/installments/index" */))
const _b8529b7c = () => interopDefault(import('../pages/payment/preorders/index.vue' /* webpackChunkName: "pages/payment/preorders/index" */))
const _4aa7708e = () => interopDefault(import('../pages/payment/select-payment.vue' /* webpackChunkName: "pages/payment/select-payment" */))
const _3b247dfa = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _40ebd0c9 = () => interopDefault(import('../pages/result/error/index.vue' /* webpackChunkName: "pages/result/error/index" */))
const _bc0de7b6 = () => interopDefault(import('../pages/result/not-supported-debts.vue' /* webpackChunkName: "pages/result/not-supported-debts" */))
const _d96c3b8e = () => interopDefault(import('../pages/result/without-debts.vue' /* webpackChunkName: "pages/result/without-debts" */))
const _6b2c42aa = () => interopDefault(import('../pages/payment/installments/list.vue' /* webpackChunkName: "pages/payment/installments/list" */))
const _056f1eb6 = () => interopDefault(import('../pages/payment/installments/partial.vue' /* webpackChunkName: "pages/payment/installments/partial" */))
const _5f3cf95e = () => interopDefault(import('../pages/payment/preorders/list.vue' /* webpackChunkName: "pages/payment/preorders/list" */))
const _2245cd71 = () => interopDefault(import('../pages/payment/preorders/partial.vue' /* webpackChunkName: "pages/payment/preorders/partial" */))
const _01eb53d2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/pac",
    component: _2cb3c695,
    name: "pac"
  }, {
    path: "/pac/manual-subscription",
    component: _3af49547,
    name: "pac-manual-subscription"
  }, {
    path: "/pac/operations-to-subscribe",
    component: _a33f0b88,
    name: "pac-operations-to-subscribe"
  }, {
    path: "/pac/success",
    component: _72247e86,
    name: "pac-success"
  }, {
    path: "/payment/down-payments",
    component: _3e7dc372,
    name: "payment-down-payments"
  }, {
    path: "/payment/error",
    component: _86c99d82,
    name: "payment-error"
  }, {
    path: "/payment/installments",
    component: _15d09276,
    name: "payment-installments"
  }, {
    path: "/payment/preorders",
    component: _b8529b7c,
    name: "payment-preorders"
  }, {
    path: "/payment/select-payment",
    component: _4aa7708e,
    name: "payment-select-payment"
  }, {
    path: "/payment/success",
    component: _3b247dfa,
    name: "payment-success"
  }, {
    path: "/result/error",
    component: _40ebd0c9,
    name: "result-error"
  }, {
    path: "/result/not-supported-debts",
    component: _bc0de7b6,
    name: "result-not-supported-debts"
  }, {
    path: "/result/without-debts",
    component: _d96c3b8e,
    name: "result-without-debts"
  }, {
    path: "/payment/installments/list",
    component: _6b2c42aa,
    name: "payment-installments-list"
  }, {
    path: "/payment/installments/partial",
    component: _056f1eb6,
    name: "payment-installments-partial"
  }, {
    path: "/payment/preorders/list",
    component: _5f3cf95e,
    name: "payment-preorders-list"
  }, {
    path: "/payment/preorders/partial",
    component: _2245cd71,
    name: "payment-preorders-partial"
  }, {
    path: "/",
    component: _01eb53d2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
